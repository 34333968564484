/*Propiedades Generales*/
/*Funtes 

Roboto
Raleway 
Nunito 
Questrial

*/

@import url("https://fonts.googleapis.com/css2?family=Raleway&family=Questrial&display=swap");

html,
body {
  height: 100% !important;
  font-family: "Raleway", "Questrial", "Times", "Arial" !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1 1;
}

.footer {
  flex-shrink: 0;
}

.footer-style {
  color: white;
}

.paddingIndex {
  padding-top: 180px;
}

.main-background {
  background: url("./../img/fondos/fondo_2.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #b2afab;
  background-position: center center;
  background-size: cover;
  /*Paper Kit*/
  background-color: #b2afab;
  background-position: 50%;
  background-size: cover;
  min-height: 95vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  padding-top: 110px;
}

.login-background {
  background: url("./../img/fondos/fondo_4.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #b2afab;
  background-position: center center;
  background-size: cover;
  /*Paper Kit*/
  background-color: #b2afab;
  background-position: 50%;
  background-size: cover;
  min-height: 100vh;
  max-height: 999px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
  display: flex;
  padding-top: 110px;
  filter: brightness(0.85);
}

.main-container {
  padding-top: 10px;
  padding-bottom: 50px;
  height: 100%;
}

.dot {
  height: 150px;
  width: 150px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot-img {
  height: 150px;
  width: 150px;
  margin-bottom: 15px;
}

.stamp {
  transform: rotate(20deg);
  color: #ff0050;
  font-size: 1rem;
  font-weight: 700;
  border: 0.25rem solid #ff0050;
  position: absolute;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  mix-blend-mode: multiply;
}

.diagram1 {
  border-radius: 8px;
  width: 55%;
  height: 75vh;
}

.diagram2 {
  border-radius: 8px;
  width: 100%;
  height: 70vh;
}

.emptyBox {
  height: 300px;
  width: 100%;
  background-color: #bbb;
  display: block;
}

.social-network {
  /*  margin-left: 50px;
  margin-right: 50px;*/
  cursor: pointer;
  padding-top: 10px;
}

.social-network-mini {
  cursor: pointer;
  margin-left: 15px;
}

.loc-line {
  border-bottom: 3px solid rgba(0, 122, 192, 1);
}

.filter-control {
  border-bottom: rgba(224, 222, 222, 0.4) solid 0.1px;
}

.interes-box {
  border: 2px solid rgba(0, 122, 192, 1);
  border-radius: 10px;
  background-color: rgba(0, 122, 192, 0.1);
}

.etiquetas-temas {
  font-size: 11px;
  background-color: #ff0050;
  color: white;
  border-radius: 10px;
  padding: 4px 10px;
}

input[type="checkbox"] {
  width: 16px !important;
  height: 16px !important;
}

/*Propiedades de Barra de Navegación*/

.nav-link {
  color: white !important;
}

.navbar-transparent {
  background-color: transparent !important;
}

.navbar-color {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.navbar-color.index-logo {
  height: 50px;
  background-color: transparent !important;
  transition: height 1s, transform 1s;
}

.index-logo {
  height: 80px;
  transition: height 1s, transform 1s;
}

.main-navbar {
  position: fixed;
  z-index: 1030;
  right: 0;
  left: 0;
  top: 0;
  padding-top: 25px;
  display: block !important;
}

/*Propiedades regulares*/

.container-regular {
  padding-left: 130px;
  padding-right: 130px;
}

.delimiter-regular {
  border-right: rgba(224, 222, 222, 0.4) solid 0.1px;
  border-left: rgba(224, 222, 222, 0.4) solid 0.1px;
  height: inherit;
}

.regular-navbar {
  position: relative !important;
}

.profilePicture {
  height: 155px;
  width: 100%;
  background-color: #bbb;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}

.questionLogo {
  height: 40px;
  width: 40px;
  border: rgba(224, 222, 222, 0.8) solid 1px;
  display: block;
  border-radius: 50%;
  object-fit: contain;
  padding: 1px;
}

.companyLogo {
  height: 155px;
  width: 100%;
  display: block;
  object-fit: contain;
}

.bullet {
  width: 100%;
  display: block;
}

.index-split {
  border-right: black 1px solid;
}

.firstRowCompany {
  padding: 20px 100px;
}

.dashHead {
  text-align: center;
  border-bottom: black solid;
}

.positionRow {
  border: rgba(0, 0, 0, 0.2) 1px solid;
  margin-top: 5px;
  border-radius: 10px;
}

.candidatosRow {
  border: rgba(255, 0, 80, 0.85) 1px solid;
  margin-top: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.activeRow {
  border: #34eb52 1.5px solid;
}

.activeIcon {
  color: #34eb52;
}

.timeline {
  padding-left: 0;
  padding-right: 0;
}

.timelineStep {
  border-bottom: #ff0050 2px solid;
}

.circleTime {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  background: none #ff0050;
  border-radius: 50%;
}

.seccion-preguntas {
  padding-left: 50px;
  padding-right: 50px;
}

.pregunta {
  border-bottom: rgba(224, 222, 222, 0.4) solid 0.1px;
}

.respuesta {
  padding-left: 50px;
}

.table-cleaver {
  background-color: rgba(245, 245, 245, 0.1);
  border-radius: 10px;
}

.table-cleaver > td,
th {
  border: none !important;
}

/*Barra dashboard de empresas*/

.smallHead {
  display: none;
}

.barra-horizontal div {
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.barra-horizontal {
  border: 2px solid white;
  border-radius: 5px;
  overflow: hidden;
  display: grid;
}

.item0 {
  background: rgba(85, 12, 47, 0.85);
}

.item1 {
  background: rgba(148, 22, 81, 0.85);
}

.item2 {
  background: rgba(255, 0, 80, 0.85);
}

.item3 {
  background: rgba(0, 240, 255, 0.85);
}

.item4 {
  background: rgba(0, 112, 192, 0.85);
}

.item5 {
  background: rgba(0, 78, 153, 0.85);
}

/*Propiedades de Formas*/

.form-row {
  padding-top: 5px;
}

.register-box {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
}

.pledge-box {
  background-color: rgba(255, 0, 80, 0.75);
  border-radius: 10px;
}

label {
  padding-top: 0px !important;
}

.has-danger > .form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 22, 81, 0.25) !important;
  border-color: rgba(148, 22, 81, 1) !important;
}

.rc-slider-track {
  background-color: rgba(255, 0, 80, 1) !important;
}

.rc-slider-handle {
  border: 2px solid rgba(255, 0, 80, 1) !important;
}

.rc-slider {
  height: 30px !important;
}

.rc-slider-dot-active {
  border-color: rgba(255, 0, 80, 1) !important;
}

.slider {
  padding-left: 10px;
  padding-right: 10px;
}

/*Propiedades de slider para test de resiliencia*/
.reSlider > .rc-slider-with-marks > .rc-slider-mark > .rc-slider-mark-text {
  font-size: 16px !important;
}

.reSlider.reActive > .rc-slider-with-marks > .rc-slider-handle {
  visibility: visible;
}

.reSlider > .rc-slider-with-marks > .rc-slider-handle {
  visibility: hidden;
}

.reSlider.reActive
  > .rc-slider-with-marks
  > .rc-slider-step
  > .rc-slider-dot-active {
  border-color: rgba(255, 0, 80, 1) !important;
}

.reSlider > .rc-slider-with-marks > .rc-slider-step > .rc-slider-dot-active {
  border-color: #fff !important;
}

/*Propiedades de slider para test de resiliencia*/

.resiResult {
  position: absolute;
  top: 40%;
  left: 45%;
}

.searchBox {
  border: black 0.5px solid;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  width: 31% !important;
  font-size: 12px;
}

.hideSearch {
  display: none;
}

/*Propiedades de Perfiles Modal*/

.profileModal {
  background-color: rgba(255, 255, 255, 0.96);
  z-index: 1031;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 50px;
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: -100%;
  }

  to {
    margin-left: 0%;
  }
}

/*Propiedades de Test Modales*/
.test-modal {
  background-color: rgba(0, 0, 0, 1);
  z-index: 1031;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: auto;
  padding-top: 50px;
}

.test-modal.test-msg {
  background-color: rgba(0, 0, 0, 0.95);
}

.progress {
  height: 0.3rem !important;
  margin-top: 8px;
}

.testQuestion {
  background-color: white;
  /*height: 400px;*/
  /*margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;*/
  margin: 10px;
  border-radius: 10px;
}

.transQuestion {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.testImage {
  max-width: 100%;
  max-height: 100%;
}

.controlQuestion {
  cursor: pointer;
}

.selectedAns {
  border: #ff0050 solid 5px;
}

.qselect {
  border: #ff0050 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.qselect.qact {
  background-color: #ff0050;
  color: white;
}

.noImg {
  height: 250px;
}

/*Pagination*/
.pagination {
  display: flex;

  /* Border */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.pagination__item {
  cursor: pointer;
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  background-color: transparent;
}
.activePage {
  color: rgba(255, 0, 80, 1);
  border: solid;
  border-color: rgba(255, 0, 80, 1);
  border-radius: 10px;
}

/*Colores Oficiales*/

/*

  Primary = rgba(0,240,255,1) // Azul Claro
  Secondary = rgba(0,122,192,1) //Azul Medio
  Warning = rgba(0,78,153) //Azul Fuerte
  Danger = rgba(148,22,81,1) //Rojo Fuerte
  Success = rgba(255,0,80,1) //Rojo-Rosa



*/

.btn-success {
  background-color: #ff0050 !important;
  border-color: #ff0050 !important;
}

.btn-primary {
  background-color: rgba(0, 240, 255, 1) !important;
  border-color: rgba(0, 240, 255, 1) !important;
}

.btn-secondary {
  background-color: rgba(0, 122, 192, 1) !important;
  border-color: rgba(0, 122, 192, 1) !important;
}

.btn-warning {
  background-color: rgba(0, 78, 153, 1) !important;
  border-color: rgba(0, 78, 153, 1) !important;
  color: white !important;
}

.btn-primary.transparent-btn {
  background-color: rgba(0, 240, 255, 0.7) !important;
  border-color: rgba(0, 240, 255, 0.7) !important;
}

.btn-success.transparent-btn {
  background-color: rgba(255, 0, 80, 0.7) !important;
  border-color: rgba(255, 0, 80, 0.7) !important;
}

.btn-warning.transparent-btn {
  background-color: rgba(0, 78, 153, 0.75) !important;
  border-color: rgba(0, 240, 255, 1) !important;
}

.transparent-color {
  background-color: rgba(255, 255, 255, 0.75);
}

.bg-warning {
  background-color: rgba(0, 78, 153, 1) !important;
}

.bg-primary {
  background-color: rgba(0, 240, 255, 0.65) !important;
}

.bg-success {
  background-color: #ff0050 !important;
}

.text-success {
  color: #ff0050 !important;
}

.text-danger {
  color: rgba(148, 22, 81, 1) !important;
}

.text-primary {
  color: rgba(0, 240, 255, 1) !important;
}

.text-secondary {
  color: rgba(0, 122, 192, 1) !important;
}

.text-warning {
  color: rgba(0, 78, 153, 1) !important;
}

a.text-primary:hover {
  color: black !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 991px) {
  .delimiter-regular {
    border: none;
    height: inherit;
  }

  .profilePicture {
    height: 150px;
    width: 50%;
    background-color: #bbb;
    display: block;
    border-radius: 50%;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
  }

  .firstRowCompany {
    padding: 10px 10px;
  }

  .btn {
    margin-top: 5px;
  }

  .searchBox {
    width: 45% !important;
  }

  .contador {
    display: none;
  }

  h4 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .positionHead {
    display: none !important;
  }

  .paddingIndex {
    padding-top: 100px;
  }

  .smallHead {
    display: block;
    text-align: center;
    padding-top: 10px;
  }

  .circleTime {
    width: 10px;
    height: 10px;
    bottom: -5px;
  }

  .index-logo {
    height: 50px;
    background-color: transparent !important;
    transition: height 1s, transform 1s;
  }

  .rc-slider-track {
    touch-action: none !important;
  }

  .rc-slider-handle {
    touch-action: none !important;
  }

  .rc-slider {
    touch-action: none !important;
  }

  .slider {
    touch-action: none !important;
  }

  .areaQuestion {
    padding-left: 20px;
    padding-right: 20px;
  }

  .controlRowQuestion {
    padding-right: 30px;
  }

  .index-split {
    border-bottom: black 1px solid;
    border-right: none;
  }
  .searchBox {
    width: 100% !important;
  }
  .slider-test {
    padding-top: 20px;
    width: 90%;
  }

  .diagram1 {
    width: 70%;
    height: 55vh;
  }

  .diagram2 {
    border-radius: 8px;
    width: 100%;
    height: 40vh;
  }

  .stamp {
    font-size: 15px;
    margin-top: 8px;
    margin-right: 8px;
  }
}

/*FADE IN*/

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*Animista Designs*/

.pulsate-bck {
  -webkit-animation: pulsate-bck 0.7s ease-in-out infinite both;
  animation: pulsate-bck 0.7s ease-in-out infinite both;
}

.swirl-in-fwd {
  -webkit-animation: swirl-in-fwd 0.6s ease-out both;
  animation: swirl-in-fwd 0.6s ease-out both;
}

.blink-2 {
  -webkit-animation: blink-2 1.3s infinite both;
  animation: blink-2 1.3s infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-23 20:24:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-23 20:30:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
    transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-8-23 21:5:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
