@media screen and (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100% !important;
    width: 230px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: rgba(0, 78, 153, 0.6);
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid rgba(0, 78, 153);
    padding-right: 0;
    padding-left: 40px;
    padding-top: 15px;
    transform: translateX(230px);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .navbar-collapse ul {
    position: relative;
    z-index: 3;
    height: 95%;
  }

  .nav-open .navbar-collapse {
    transform: translateX(0);
    padding-top: 40px;
  }

  .nav-open .navbar-translate {
    transform: translateX(-230px);
  }

  .navbar-collapse.has-image:after,
  .navbar-collapse:after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    content: "";
    z-index: 1;
  }
}
